import './DoneModal.css';

const DoneModal = () => {
    return (
        <div className='done__modal'>
            Повідомлення відправлено !
        </div>
    )
};

export default DoneModal;